<template>
  <div>
    <h1>Invoices</h1>
    <ul class="list-group list-group-flush mb-2">
      <li class="list-group-item font-weight-bold">
        <div class="row">
          <div class="col">Invoice</div>
          <div class="col">Created date</div>
          <div class="col">Total</div>
          <div class="col">Paid date</div>
          <div class="col"></div>
        </div>
      </li>
      <li class="list-group-item" v-for="invoice in invoices" v-bind:key="invoice.id">
        <div class="row">
          <div class="col">
            <span class="badge bg-danger text-center mr-2" v-if="!invoice.paid_date">Unpaid</span>
            <span class="badge bg-success text-center mr-2" v-else>Paid</span>
            #{{ invoice.id }}
          </div>
          <div class="col">{{ invoice.created_at | formatDate }}</div>
          <div class="col">
            <span v-if="invoice.currency.prefix">{{ invoice.currency.prefix }} </span>{{ invoice.total }}<span v-if="invoice.currency.decimal">.00</span><span v-if="invoice.currency.suffix">{{ invoice.currency.suffix }}</span>
          </div>
          <div class="col">
            <span>{{ invoice.paid_date | formatDate }}</span>
          </div>
          <div class="col text-right">
            <!-- <button class="btn btn-sm btn-success mr-2">Download</button> -->
            <router-link :to="'/invoices/' + invoice.id" class="mr-3"><button class="btn btn-sm btn-primary">Show more</button></router-link>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      invoices: {},
    };
  },
  async mounted() {
    const { data } = await this.$http.get("/invoices");
    this.invoices = data;
  },
};
</script>